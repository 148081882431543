$font-path: 'fonts' !default;
$accent-color: #00c874 !default;
$bg-path: url('../images/wallpapers/lion.jpg') !default;
//noinspection CssUnknownTarget
$bg-path-edge: url('images/wallpapers/lion.jpg') !default;
$bg-transition: background-image 0.5s ease-in-out, filter 0.5s linear, backdrop-filter 0.5s linear !default;
$zoom-amount: 1 !default;
$blur-amount: 0 !default;
$brightness-amount: 60% !default;
$opacity-amount: 100% !default;
$border-width: 1px !default;
$border-color: rgba(172, 172, 172, 0.5) !default;