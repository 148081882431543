@import 'variables';

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Thin.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	font-style: italic;
	src: url('#{$font-path}/Roboto-ThinItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: italic;
	src: url('#{$font-path}/Roboto-LightItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: italic;
	src: url('#{$font-path}/Roboto-Italic.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: italic;
	src: url('#{$font-path}/Roboto-MediumItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: italic;
	src: url('#{$font-path}/Roboto-BoldItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	font-style: normal;
	src: url('#{$font-path}/Roboto-Black.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	font-style: italic;
	src: url('#{$font-path}/Roboto-BlackItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 100;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Thin.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 100;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-ThinItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 200;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-ExtraLight.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 200;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-LightItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-Italic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-MediumItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-SemiBold.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-BoldItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 800;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-ExtraBold.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 800;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-ExtraBoldItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 900;
	font-style: normal;
	src: url('#{$font-path}/Montserrat-Black.woff2') format('woff2');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 900;
	font-style: italic;
	src: url('#{$font-path}/Montserrat-BlackItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'Six Caps';
	font-weight: 400;
	font-style: normal;
	src: url('#{$font-path}/SixCaps-Regular.woff2') format('woff2');
}