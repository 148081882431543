/* region Variables */

@import 'variables';

:root {
	--zoom-amount: #{$zoom-amount};
	--blur-background-image: #{$bg-path};
	--blur-amount: #{$blur-amount};
	--brightness-amount: #{$brightness-amount};
	--opacity-amount: #{$opacity-amount};
	--accent-color: #{$accent-color};
}

/* endregion */

/* region Base */

//noinspection CssOverwrittenProperties
:focus {
	//outline: auto 1px $accent-color;
	outline: none;
	border: 1px solid rgba($accent-color, 0.4) !important;
	box-shadow: 0 0 15px $accent-color !important;
	box-shadow: 0 0 15px var(--accent-color) !important;
}

html {
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.5);
	outline: $border-color solid $border-width;
	outline-offset: -1px;
}

body {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	margin: 5px;
	background-color: #303030;
	//background-image: #{$bg-path};
	//background-image: linear-gradient(#303030, #000);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	transition: $bg-transition;
	font-family: Montserrat, 'Segoe UI', sans-serif;
	color: #fff;
	overflow: hidden;
}

video.background-video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	object-fit: cover;
	object-position: center top;
	z-index: -1;
	transform: scale(var(--zoom-amount));
	transform-origin: center top;
}

/* region Browser */

html {
	&.browser {
		margin: 0;
		height: 100%;
		outline: none;

		header.titlebar {
			display: none;
		}

		main.content {
			margin-top: 0;
			height: 100%;
		}

		.header {
			.row-1.column-3 {
				.spacer, .button {
					display: none;
				}
			}
		}

		.electron {
			display: none;
		}
	}
}

/* endregion */

/* endregion */

/* region Titlebar */

header.titlebar {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100% - 10px);
	height: 22px;
	margin: 5px;
	//box-shadow: inset 0 0 10px rgba(255, 255, 255, 1);
	//background-color: rgba(0, 0, 0, 0.6);
	//border-top-left-radius: 10px;
	//border-top-right-radius: 10px;
	color: #fff;
	-webkit-user-select: none;
	//overflow: hidden;
	z-index: -1;
	-webkit-app-region: no-drag;

	.drag-region {
		display: grid;
		width: 100%;
		height: 100%;
		-webkit-app-region: drag;
		grid-template-columns: auto 138px;

		.window-title {
			grid-column: 1;
			display: flex;
			align-items: center;
			margin-left: 2px;
			overflow: hidden;
			font-size: 16px;
			text-shadow: 0 0 3px #000;
			height: 100%;

			img.icon {
				width: 22px;
				height: 22px;
				//background-color: rgba(71, 71, 71, 1);
				background-color: rgba(0, 0, 0, 1);
				border-radius: 5px;
			}

			span {
				font-family: 'Segoe UI', sans-serif;
				-webkit-user-select: none;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.window-controls {
			display: grid;
			//height: 100%;
			height: 31px;
			position: absolute;
			top: -4px;
			right: -4px;
			grid-template-columns: repeat(3, 46px);
			font-family: 'Segoe MDL2 Assets', sans-serif;
			font-size: 10px;
			-webkit-app-region: no-drag;

			.button {
				grid-row: 1 / span 1;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				-webkit-user-select: none;
				cursor: pointer;
				color: #bbb;

				&:hover {
					background: rgba(255, 255, 255, 0.2);
					color: #fff;
				}

				&.minimize {
					grid-column: 1;
				}

				&.maximize, &.restore {
					grid-column: 2;
				}

				&.restore {
					display: none;
				}

				&.exit {
					grid-column: 3;

					&:hover {
						background: #E81123;
					}
				}
			}
		}
	}
}

/* endregion */

/* region Main Content */

main.content {
	position: relative;
	width: 100%;
	height: calc(100% - 27px);
	//padding: 10px;
	margin-top: 32px;
	border-radius: 10px;
	border: $border-color solid $border-width;
	background-color: rgba(0, 0, 0, 0.3);
	-webkit-user-select: none;
	-webkit-app-region: drag;
	color: #fff;
	//perspective: 0; // Strange effect in Firefox and Chrome
}

/* region Header */

.header {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-auto-rows: minmax(50px, auto);
	height: 100px;
	margin: 10px;
	border: $border-color solid $border-width;
	border-radius: 5px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
	//backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount)) opacity(var(--opacity-amount));
	backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount));
	opacity: var(--opacity-amount);
	transition: $bg-transition;
	//z-index: 0;

	img.icon {
		width: 30px;
		height: 30px;
		//background-color: rgba(71, 71, 71, 1);
		background-color: rgba(0, 0, 0, 1);
		border-radius: 5px;
	}

	&::after {
		//position: absolute;
		width: 100%;
		height: 1px;
		display: block;
		content: '';
		margin-top: 99px;
		background-image: linear-gradient(to right, rgba(205, 205, 205, 0), rgba(205, 205, 205, 0.5), rgba(205, 205, 205, 0));
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 1px;
	}

	.button {
		width: 30px;
		height: 30px;
		//background-color: #000;
		border-radius: 5px;
		-webkit-user-select: none;
		cursor: pointer;
		transition: transform .8s ease-in-out;

		&.minimize {
			background-image: url(../images/buttons/minimize.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.maximize {
			background-image: url(../images/buttons/maximize.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.restore {
			background-image: url(../images/buttons/restore.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.exit {
			background-image: url(../images/buttons/exit.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.fullscreen {
			background-image: url(../images/buttons/fullscreen.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.unfullscreen {
			background-image: url(../images/buttons/unfullscreen.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.grid {
			background-image: url(../images/buttons/grid.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.list {
			background-image: url(../images/buttons/list.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&.settings {
			position: relative;
			background-color: #0b0b0b;

			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 100%;
				background-image: url(../images/buttons/gear.svg);
				background-repeat: no-repeat;
				background-position: center;
				transition: transform .8s ease-in-out;
			}

			&:hover::after {
				transform: rotate(180deg);
			}
		}
	}

	.item {
		padding: 10px;
	}

	.row-1.column-2 {
		background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%), url(../images/titlebar-decoration.svg), linear-gradient(to right, rgba(205, 205, 205, 0), rgba(205, 205, 205, 1), rgba(205, 205, 205, 0));
		background-repeat: repeat, no-repeat, no-repeat;
		background-position: center center, center top, center bottom;
		background-size: auto auto, auto auto, auto 1px;
		padding: 0;
		color: #fff;
		line-height: 50px;
		font-family: 'Six Caps', sans-serif;
		font-weight: 400;
		font-size: 24px;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 0 4px 10px #000;
		text-indent: 10px;
		letter-spacing: 0.35em;
	}

	.row-1.column-3 {
		display: grid;
		grid-template-columns: auto repeat(3, 30px);
		grid-auto-rows: minmax(30px, auto);
		column-gap: 10px;
		align-items: center;
		justify-items: end;
		overflow: hidden;

		.spacer {
			display: block;
		}

		.button {
			display: block;
			-webkit-app-region: no-drag;

			&.restore {
				display: none;
			}
		}
	}

	.row-2.column-2 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-rows: minmax(50px, auto);
		column-gap: 10px;
		align-items: center;
		justify-items: center;
		padding: 0;
		color: #fff;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-size: 24px;
		text-transform: uppercase;

		span {
			display: block;
			height: 50px;
			line-height: 50px;
			-webkit-app-region: no-drag;
			text-shadow: 0 3px 5px #000;
			transition: all 150ms ease-in-out;

			&::after {
				display: block;
				content: '';
				height: 3px;
				background-color: transparent;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-top: -5px;
				transition: all 150ms ease-in-out;
			}

			//noinspection CssOverwrittenProperties
			&.selected, &:hover {
				color: $accent-color;
				color: var(--accent-color);
				text-shadow: 0 0 8px $accent-color;
				text-shadow: 0 0 8px var(--accent-color);

				&::after {
					background-color: $accent-color;
					background-color: var(--accent-color);
				}
			}

			&:hover:not(.selected) {
				cursor: pointer;
			}
		}
	}

	.row-2.column-3 {
		display: grid;
		grid-template-columns: auto repeat(3, 30px);
		grid-auto-rows: minmax(30px, auto);
		column-gap: 10px;
		align-items: center;
		justify-items: end;

		.button {
			-webkit-app-region: no-drag;
		}
	}
}

/* endregion */

/* region Game Container */

.game-container {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-auto-rows: minmax(50px, auto);
	position: relative;
	height: calc(100% - 130px);
	margin-bottom: 10px;

	div, p {
		text-shadow: 0 0 3px #000;
		line-height: 36px;
		margin: 0;
	}

	.game-list {
		margin-right: 10px;
		border-top: $border-color solid $border-width;
		border-right: $border-color solid $border-width;
		border-bottom: $border-color solid $border-width;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		box-shadow: 4px 0 4px rgba(0, 0, 0, 0.5);
		//backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount)) opacity(var(--opacity-amount));
		backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount));
		opacity: var(--opacity-amount);
		transition: $bg-transition;
	}

	.game-details {
		padding: 10px;
		margin-right: 10px;
		border: $border-color solid $border-width;
		border-radius: 5px;
		box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5);
		//backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount)) opacity(var(--opacity-amount));
		backdrop-filter: blur(var(--blur-amount)) brightness(var(--brightness-amount));
		opacity: var(--opacity-amount);
		transition: $bg-transition;

		select.background-selector {
			width: 250px;
			-webkit-app-region: no-drag;
		}

		.background-zoom {
			display: none;
		}
	}
}

/* endregion */

/* endregion */

/* region Widgets */

/* region Select */

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: #000;
	background-image: url(../images/buttons/arrow.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - 10px) center;
	border: 1px solid #474747;
	border-radius: 4px;
	outline: none;
	//height: 35px;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	padding: 10px 30px 10px 10px;
	color: #c9c9c9;
	cursor: pointer;

	option {
		color: #c9c9c9;
		background-color: #2B2B2B;
		border: 1px solid #474747;
		border-radius: 5px;
		outline: none;
		text-transform: capitalize;
	}
}

.select2, .select2-results__option {
	-webkit-app-region: no-drag;
}

.select2-container--default .select2-selection--single {
	background-color: #000;
	border: 1px solid #474747;
	border-radius: 4px;
}

.select2-container .select2-selection--single {
	height: auto;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #fff transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #fff transparent;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #c9c9c9;
	text-transform: capitalize;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: var(--accent-color);
	color: #2b2b2b;
}

.select2-container--default .select2-results__option .select2-results__option {
	text-transform: capitalize;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
	background-color: darken($accent-color, 15%);
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 624px;
}

.select2-dropdown {
	background-color: #2b2b2b;
	border: 1px solid #474747;
	border-radius: 4px;
	//margin-top: 2px;
	overflow: hidden;
}

/* endregion */

/* region Slider */

.ui-state-default, .ui-widget-content .ui-state-default {
	background: #474747;
	border: 1px solid #474747;
	box-sizing: border-box;
	box-shadow: 0 0 10px #000;
	color: #fff;
}

.ui-state-focus, .ui-widget-content .ui-state-focus {
	background: #474747;
	border: 1px solid #474747;
	box-sizing: border-box;
	box-shadow: 0 0 10px #000;
	color: #fff;
}

.ui-state-active, .ui-widget-content .ui-state-active {
	background: #474747;
	border: 1px solid #474747;
	box-sizing: border-box;
	box-shadow: 0 0 10px #000;
	color: #fff;
}

//noinspection CssReplaceWithShorthandSafely
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
	border-bottom-right-radius: 100%;
	border-bottom-left-radius: 100%;
}

//noinspection CssOverwrittenProperties
.ui-slider-horizontal .ui-slider-range {
	height: 8px;
	background: $accent-color;
	background: var(--accent-color);
	box-shadow: 0 0 15px $accent-color;
	box-shadow: 0 0 15px var(--accent-color);
	border-radius: 2px;
	margin-top: 4px;
	margin-left: 4px;
	cursor: pointer;
}

.ui-widget-header {
	background: #000;
}

.ui-widget-content {
	background: #000;
}

.ui-widget.ui-widget-content {
	border: 1px solid #474747;
	border-radius: 4px;
}

.ui-slider-horizontal {
	height: 18px;
}

.slider.ui-slider {
	width: 96%;
	margin: 10px 2%;
	cursor: pointer;
	-webkit-app-region: no-drag;

	.ui-slider-handle {
		width: 32px;
		height: 32px;
		top: 50%;
		margin-top: -16px;
		margin-left: -16px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
		font-size: 10px;
		text-align: center;
		line-height: 32px;
		cursor: e-resize;
		-webkit-app-region: no-drag;
	}
}

/* endregion */

/* region Scrollbar */

.ps {
	position: relative;
	overflow: hidden !important;
	-ms-touch-action: auto;
	touch-action: auto;
	-ms-overflow-style: none;
	overflow-anchor: none;
}

.ps__rail-x {
	position: absolute;
	bottom: 0;
	display: none;
	height: 15px;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
}

.ps__rail-y {
	position: absolute;
	right: 0;
	display: none;
	width: 15px;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
	display: block;
	background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
	opacity: .6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
	background-color: #eee;
	opacity: .9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
	position: absolute;
	bottom: 2px;
	height: 6px;
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, height .2s ease-in-out;
}

.ps__thumb-y {
	position: absolute;
	right: 2px;
	width: 6px;
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, width .2s ease-in-out;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
	height: 11px;
	background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
	width: 11px;
	background-color: #999;
}

@supports (-ms-overflow-style: none) {
	.ps {
		overflow: auto !important;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ps {
		overflow: auto !important;
	}
}

/* endregion */

/* endregion */

/* region Effects */

@supports not (backdrop-filter: blur(5px)) {
	.blur {
		position: relative;
		overflow: hidden;

		&::before {
			position: absolute;
			top: -50%;
			left: -50%;
			display: block;
			width: 200%;
			height: 200%;
			content: '';
			background-image: var(--blur-background-image);
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
			background-attachment: fixed;
			transition: $bg-transition;
			//filter: blur(var(--blur-amount)) brightness(var(--brightness-amount)) opacity(var(--opacity-amount));
			filter: blur(var(--blur-amount)) brightness(var(--brightness-amount));
			z-index: -1;
		}
	}
}

/* endregion */

/* region Loading */

.uil-loading {
	position: absolute;
	left: calc(50% - 100px);
	top: calc(50% - 25px);
	width: 200px;
	height: 50px;

	.ld-l {
		opacity: 0;
		animation: ld-in 3s 0.0s ease infinite;
	}

	.ld-o {
		opacity: 0;
		animation: ld-in 3s 0.1s ease infinite;
	}

	.ld-a {
		opacity: 0;
		animation: ld-in 3s 0.2s ease infinite;
	}

	.ld-d {
		opacity: 0;
		animation: ld-in 3s 0.3s ease infinite;
	}

	.ld-i {
		opacity: 0;
		animation: ld-in 3s 0.4s ease infinite;
	}

	.ld-n {
		opacity: 0;
		animation: ld-in 3s 0.5s ease infinite;
	}

	.ld-g {
		opacity: 0;
		animation: ld-in 3s 0.6s ease infinite;
	}
}

.uil-ripple {
	position: absolute;
	left: calc(50% - 90px);
	top: calc(50% - 90px);
	opacity: 1;
}

@keyframes ld-in {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	30% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

/* endregion */

/* region Browser Fixes */

/* region Firefox Fixes */

@-moz-document url-prefix() {
	/*video.background-video {
		z-index: -1;
	}*/
}

/* endregion */

/* region Edge Fixes */

@supports (-ms-ime-align: auto) {
	:root {
		--blur-background-image: #{$bg-path-edge};
	}

	.blur {
		position: initial;
	}

	.background-video-wrapper {
		position: fixed;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;

		video.background-video {
			min-width: 50%;
			min-height: 50%;
		}
	}
}

/* endregion */

/* endregion */